import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch,
    useRouteMatch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../../components/Helmet";
import Page404 from "../../Page404";

import LeadDetailOverview from "./LeadDetailOverview";
import LeadDetailPrint from "./LeadDetailPrint";
import LeadDetailReminders from "./LeadDetailReminders";
import LeadDetailPlanning from "./LeadDetailPlanning";
import LeadDetailOutages from "./LeadDetailOutages";
import LeadDetailImages from "./LeadDetailImages";
import LeadDetailEdit from "./LeadDetailEdit";
import LeadDetailInstallations from "./LeadDetailInstallations";
import LeadDetailAppointment from "./LeadDetailAppointment";
import ViewNotifier from "../../../components/ViewNotifier";

function LeadDetail(props) {
    const leadId = props.match.params.id;
    const urlPrefix = "/lead/" + leadId;

    const [lead, setLead] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const refreshLead = useCallback(() => {
        setLead(null);
        setError(null);
        setErrorCode(null);
        axios.post("/getLead", { id: leadId })
            .then((response) => {
                if(response.data.valid) {
                    setLead(response.data.data);
                } else {
                    setLead(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((error) => {
                console.error(error);
                setLead(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [leadId]);

    useEffect(() => {
        if(isNaN(parseInt(leadId))) {
            return;
        }
        refreshLead();
    }, [leadId, refreshLead]);

    const noTabBar = useRouteMatch([
        `${urlPrefix}/print`
    ]) !== null;

    const childProps = {
        leadId,
        lead,
        error,
        setLead,
        refreshLead
    };

    if(isNaN(parseInt(leadId)) || leadId < 1 || errorCode === "DOESNT_EXIST") {
        return <Page404/>
    }

    return (
        <React.Fragment>
            <Helmet title={ "Lead #" + leadId + (lead ? ` - ${lead.name}` : "") }/>

            <Title preTitle="Detail" noBottom={ error === null && !noTabBar }>
                Lead <small className="text-muted">#{leadId}</small>
            </Title>

            <ViewNotifier leadId={ leadId }/>

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : (
                <React.Fragment>
                    { !noTabBar && (
                        <TabBar>
                            <TabBarItem to={ urlPrefix }>
                                Overzicht
                            </TabBarItem>
                            <TabBarItem to={ urlPrefix + "/reminders" }>
                                Herinneringen
                            </TabBarItem>
                            <TabBarItem to={ urlPrefix + "/planning" }>
                                Planning
                            </TabBarItem>
                            <TabBarItem to={ urlPrefix + "/installations" }>
                                Installaties
                            </TabBarItem>
                            <TabBarItem to={ urlPrefix + "/outages" }>
                                Storingen
                            </TabBarItem>
                            <TabBarItem to={ urlPrefix + "/images" }>
                                Foto's
                            </TabBarItem>
                            <TabBarItem to={ urlPrefix + "/appointment"}>
                                Afspraak
                            </TabBarItem>
                            <TabBarItem to={ urlPrefix + "/edit" } className="float-right">
                                Bewerken
                            </TabBarItem>
                        </TabBar>
                    )}

                    <Switch>
                        <Route path={ urlPrefix } exact>
                            <LeadDetailOverview { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/print" }>
                            <LeadDetailPrint { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/reminders" }>
                            <LeadDetailReminders { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/planning" }>
                            <LeadDetailPlanning { ...childProps }/>
                        </Route>
		                <Route path={ urlPrefix + "/installations" }>
		                    <LeadDetailInstallations { ...childProps }/>
		                </Route>
                        <Route path={ urlPrefix + "/outages" }>
                            <LeadDetailOutages { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/images" }>
                            <LeadDetailImages { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/appointment" }>
                            <LeadDetailAppointment { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/edit" }>
                            <LeadDetailEdit { ...childProps }/>
                        </Route>

                        <Route path="/">
                            <Redirect
                                to={{
                                    pathname: urlPrefix,
                                    state: {from: "/"}
                                }}
                            />
                        </Route>
                    </Switch>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default React.memo(LeadDetail);
